.radioButtonClassificazione {
  .specie {
    text-align: center;
    margin-bottom: 1rem;
    display: table;
  }
  .etichetta {
    font-size: 1rem;
    line-height: normal;
    text-align: center;
    display: block;
  }
  .ant-radio-wrapper {
    margin-right: 0;
    span {
      display: grid;
      height: auto;
    }
    &:hover,
    &:focus,
    &:active {
      border-color: $primario;
      border-right-width: 1px !important;
      outline: 0;
      box-shadow: 0 0 0 2px rgba(0, 102, 101, 0.5) !important;
      text-decoration: underline;
    }
  }
  .ant-radio-group {
    .ant-radio {
      opacity: 0;
      width: 0;
      height: 0;
    }
    .ant-image {
      .ant-image-img {
        opacity: 0.25;
      }
      .classificazioneSelezionata {
        opacity: 1;
      }
    }
  }
}

.drawerSpecieMobile {
  .radioButtonClassificazione .ant-image .ant-image-img {
    width: 3rem;
  }
}

.listaSpecie {
  .ant-btn {
    border: none;
    box-shadow: none;
    background-color: $bianco;
    padding: 0 0.5rem;
    margin-right: 0.5rem;
    color: $primario;
    &:hover,
    &:focus,
    &:active {
      border-color: $primario;
      border-right-width: 1px !important;
      outline: 0;
      box-shadow: 0 0 0 2px rgba(0, 102, 101, 0.5) !important;
      text-decoration: underline;
    }
    img {
      width: 2.5rem;
      margin-right: 0.5rem;
    }
  }
}

.invisibleModal {
  opacity: 0;
}

@media screen and (max-width: 699px) {
  .ant-radio-group {
    display: grid;
    margin-left: 5px;
  }
}
