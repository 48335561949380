a {
  color: $primario;
  font-size: 1rem;
  font-weight: 700;
  &:hover,
  &:focus,
  &:active {
    color: $primario;
    border-color: #9d0129;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(157, 1, 41, 0.3) !important;
    text-decoration: underline;
  }
}
h1,
h2,
h3 {
  color: $bianco;
  font-size: 2rem;
  font-weight: 700;
  padding: 0;
  margin: 0;
}
h2 {
  font-size: 1.2rem;
  line-height: 2rem;
  padding-top: 0.5rem;
}
h3 {
  font-size: 1rem;
  line-height: 1.5rem;
}

.pageHeader {
  text-align: center;
  background-color: $primario;
}
.ant-empty {
  color: $primario;
}
.ant-btn {
  border: none;
  box-shadow: none;
  background-color: $primario;
  padding: 0 0.5rem;
  margin-right: 0.5rem;
  color: $bianco;
  margin-bottom: 0.5rem;
  &:hover,
  &:focus {
    color: $bianco;
    background-color: $primario;
    opacity: 0.7;
    span {
      text-decoration: underline;
    }
  }
  span.anticon {
    width: 1.5rem;
    min-width: 1.5rem;
  }
}

input:hover,
input:active,
input:focus,
textarea:hover,
textarea:active,
textarea:focus {
  border-color: $primario;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(0, 102, 101, 0.5) !important;
  text-decoration: underline;
}

.ant-upload.ant-upload-select-picture-card > .ant-upload:hover,
.ant-upload.ant-upload-select-picture-card > .ant-upload:active,
.ant-upload.ant-upload-select-picture-card > .ant-upload:focus {
  border-color: $primario;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(0, 102, 101, 0.5) !important;
  text-decoration: underline;
}
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio:hover .ant-image,
.ant-radio-wrapper:active .ant-radio,
.ant-radio:active .ant-radio-inner,
.ant-radio:active .ant-image,
.ant-radio-wrapper:focus .ant-radio,
.ant-radio:focus .ant-radio-inner,
.ant-radio:focus .ant-image,
.ant-radio-input:hover + .ant-radio-inner,
.ant-radio-input:active + .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: $primario;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(0, 102, 101, 0.5) !important;
  text-decoration: underline;
}
.ant-picker-input > input:focus,
.ant-picker-input > input-focused {
  border-color: $primario;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(0, 102, 101, 0.5) !important;
  text-decoration: underline;
}

.ant-divider-horizontal {
  border-top: 3px solid rgba(0, 102, 101, 0.5);
}

[ant-click-animating-without-extra-node]:after {
  -webkit-animation: none !important;
  -moz-animation: none !important;
  -o-animation: none !important;
  -ms-animation: none !important;
  animation: none !important;
}

.ant-form {
  margin: 1.5rem auto 2.5rem;
  min-width: 30%;
  max-width: 50%;
  &.mobileForm {
    min-width: 90%;
    max-width: 90%;
  }
  .ant-form-item {
    margin-bottom: 1.5rem;
    padding-top: 1.5rem;
    .ant-form-item-control {
      min-width: 100%;
    }
  }
}

.ant-row {
  &.mobileTable {
    max-width: 95%;
    margin: 0.5rem auto 1rem;
  }
}

.ant-spin-spinning {
  position: relative;
  margin-top: 3rem;
  display: block;
  .ant-spin-dot {
    font-size: 38px;
    width: 1em;
    height: 1em;
    .ant-spin-dot-item {
      width: 20px;
      height: 20px;
    }
  }
}

.ldDatePickerDropdown {
  left: 2rem;
  max-width: 20rem;
  .ant-picker-panel {
    padding: 0.5rem;
  }
}

.ant-modal {
  /*  width: max-content !important;*/
  width: auto;
  height: auto;
  .ant-modal-close-x {
    width: 2rem;
    height: 2rem;
    .anticon {
      top: 5px;
      position: absolute;
      right: 5px;
    }
  }
  .ant-modal-body {
    padding: 0;
    display: flex;
    .ant-image {
      width: auto !important;
      .ant-image-img {
        width: auto;
        height: auto;
      }
    }
  }
}

.ant-radio-wrapper {
  align-items: center;
  height: auto;
  margin-right: 1rem;
  .ant-radio .ant-radio-inner {
    margin: 0 0.2rem 0 0;
    border-color: $primario;
    &::after {
      background-color: $primario;
    }
  }
}
.ant-drawer {
  .ant-drawer-header-no-title,
  .ant-drawer-header {
    color: $bianco;
    background-color: $primario;
    .ant-drawer-close,
    .ant-drawer-close:focus,
    .ant-drawer-close:hover {
      color: $bianco;
    }
    .ant-drawer-title {
      margin: 0;
      color: $bianco;
      font-size: 1rem;
    }
  }
}

.nomeScientifico {
  font-style: italic;
}

.link {
  display: grid;
  margin: 1.5rem 1rem;
}
.accessoSpid,
.accessiAlternativi {
  margin: 1.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  .ant-btn {
    display: flex;
    align-items: center;
    border: none;
    box-shadow: none;
    background-color: $primario;
    padding: 0 0.5rem;
    margin: 0.5rem;
    color: $bianco;
    &:hover,
    &:focus {
      color: $bianco;
      background-color: $primario;
      opacity: 0.7;
      span {
        text-decoration: underline;
      }
    }
  }
}

.ant-dropdown {
  &:not(.ant-dropdown-hidden) {
    display: grid;
  }
  background-color: white;
  border: 1px solid $primario;
  padding: 2px;
  border-radius: 0.3rem;
  .ant-btn {
    font-size: 1rem;
    font-weight: 700;
    color: $primario;
    &:hover {
      color: $primario;
      text-decoration: underline;
    }
  }
  .ant-menu {
    .ant-menu-item:not([data-menu-id$="accedi"]) {
      color: $primario;
      span {
        align-self: center;
        max-width: 1rem;
      }
      a {
        color: $primario;
        font-weight: normal;
      }
      &[data-menu-id$="noSpid"] a {
        font-weight: bold;
      }
    }
    .ant-menu-item-selected:not([data-menu-id$="accedi"]) {
      a,
      a:hover {
        font-weight: bold;
      }
    }
    &:not(.ant-menu-horizontal) .ant-menu-item-selected:not([data-menu-id$="accedi"]) {
      .anticon .cls-1 {
        fill: $primario;
      }
      .anticon .cls-1s {
        stroke: $primario;
      }
    }
  }
}

.iOsInstallationDrawer {
  .ant-drawer-body {
    padding: 0.5rem 24px;
  }
  .ant-btn {
    background-color: transparent;
  }
  .ant-drawer-content-wrapper {
    height: auto !important;
  }
  .ant-drawer-close:focus,
  .ant-drawer-close:hover {
    text-decoration: underline;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.fa-note-legali {
  .cls-1,
  .cls-2,
  .cls-3,
  .cls-4 {
    fill: none;
    stroke: $primario;
    stroke-miterlimit: 10;
  }
  .cls-1 {
    fill: $primario;
    stroke-width: 3px;
  }
  .cls-2 {
    stroke-width: 2px;
  }
  .cls-3 {
    stroke: $primario;
    stroke-width: 3px;
  }
  .cls-4 {
    fill: $bianco;
    stroke-width: 3px;
  }
}
/* CREDITS */
.credits {
  .ant-col {
    padding: 2rem;
  }
}
