.custom-icons-list > .anticon {
  margin-right: 6px;
}

.ant-row-rtl .custom-icons-list > .anticon {
  margin-right: 0;
  margin-left: 6px;
}

.cls-1 {
  fill: #1d1e1b;
}

.cls-2 {
  fill: #fff;
}

.cls-3,
.cls-4 {
  fill: none;
  stroke: #1d1d1b;
  stroke-miterlimit: 10;
}

.cls-3 {
  stroke-width: 0.5px;
}

.cls-4 {
  stroke-width: 3px;
}

.cls-primario {
  fill: $primario;
}
.cls-bianco {
  fill: $bianco;
}

.cls-1s {
  fill: none;
  stroke: #221f20;
  stroke-miterlimit: 10;
}

.cls-1t {
  fill: #016665;
}

.clsUserIcon-1 {
  fill: #fff;
  stroke: #2764ae;
  stroke-miterlimit: 10;
  stroke-width: 0.5px;
}

.clsUserIcon-2 {
  fill: #2764ae;
}

.cls-1apple {
  fill: none;
  stroke: #1d1d1b;
  stroke-miterlimit: 10;
  stroke-width: 5px;
}
.cls-2apple {
  fill: #1d1d1b;
}
