.drawerSpecie {
  .ant-drawer-header {
    border-radius: 0;
    background-color: $primario;
    text-align: center;
    .ant-drawer-title {
      color: $bianco;
    }
  }
  .ant-drawer-content-wrapper {
    .ant-list-lg .ant-list-item {
      border-bottom: none !important;
    }
  }
}

.drawerDettaglioMobile,
.drawerDettaglio {
  .ant-drawer-header {
    background-color: $primario;
    text-align: center;
    .ant-drawer-title {
      color: $bianco;
    }
    .ant-drawer-close {
      &:hover,
      &:focus,
      &:active {
        border-color: $rossoScuro;
        border-right-width: 1px !important;
        outline: 0;
        box-shadow: 0 0 0 2px rgba(157, 1, 41, 0.3) !important;
        text-decoration: underline;
      }
    }
  }
  .ant-drawer-body {
    padding: 1rem;
  }
  .immaginiAnteprima {
    text-align: center;
  }
  .fieldDettaglio {
    font-weight: 700;
    margin-right: 10px;
  }
  #mappaDistribuzione {
    width: 100%;
  }
}
