.desktopHeader,
.mobileHeader {
  background-color: $primario;
  height: auto;
  padding: 0;
  h1 {
    color: #fff;
    position: absolute;
    left: 43px;
    bottom: 0;
    font-weight: 500;
    font-size: 1rem;
  }
  .ant-image {
    display: block;
  }
  .headerTitle {
    color: $bianco;
    align-self: center;
    span {
      font-size: 2rem;
      font-weight: 700;
    }
  }
}

.mobileHeader {
  h1 {
    line-height: 1rem;
    font-size: 0.9rem;
  }
  .ant-image {
    width: 21rem;
  }
  .menu {
    .mobileMenuButton {
      margin: 0;
      padding: 0;
      right: 0.3rem;
    }
  }
}

.mobileMenu {
  .linkTitle {
    background-color: $primario;
    width: 100%;
    #logo {
      margin-bottom: 0;
    }
  }
  .headerTitle {
    color: $bianco;
    font-size: 1.5rem;
  }
  .mobileLoginButton {
    padding-left: 0.25rem;
    display: flex;
    align-items: center;
    span {
      margin: 2px;
      font-size: 16px;
    }
    .anticon {
      svg {
        max-width: 100% !important;
      }
    }
  }
}

.menuDrawer {
  .ant-drawer-body {
    padding: 0;
    display: grid;
    .ant-menu {
      .ant-menu-item,
      .ant-menu-submenu {
        height: auto;
        color: $primario;
      }
      .ant-menu-item:not([data-menu-id$="accedi"]) {
        display: flex;
        color: $primario;
        span {
          align-self: center;
          svg {
            max-width: 1rem;
          }
        }
        a {
          color: $primario;
          font-weight: normal;
        }
        &[data-menu-id$="noSpid"] a {
          font-weight: bold;
        }
      }
      .ant-menu-item-selected:not([data-menu-id$="accedi"]) {
        a,
        a:hover {
          font-weight: bold;
        }
      }
      .ant-menu-submenu {
        color: $primario;
        .ant-menu-submenu-title {
          color: $primario;
          font-size: 1rem;
          .ant-menu-item-icon {
            max-width: 1rem;
          }
          .ant-menu-title-content:hover {
            text-decoration: underline;
          }
        }
      }
      &:not(.ant-menu-horizontal)
        .ant-menu-item-selected:not([data-menu-id$="accedi"]) {
        .anticon .cls-1 {
          fill: $primario;
        }
        .anticon .cls-1s {
          stroke: $primario;
        }
      }
    }

    .mobileMenu {
      align-content: flex-start;
      h1 {
        font-size: 1rem;
        position: absolute;
        bottom: 0;
        left: 3.5rem;
        font-weight: 500;
      }
      .linkTitle {
        background-color: $primario;
        width: 100%;
        align-items: center;
      }
      .headerTitle {
        color: $bianco;
        font-size: 1.5rem;
      }
      .userName {
        background-color: $primario;
        padding: 0 0.5rem;
        color: $bianco;
        align-items: center;
        line-height: initial;
      }
      #logoMenuMobile {
        display: grid;
        justify-items: left;
        background-color: $primario;
        .ant-image-img {
          height: 5rem;
          width: auto;
        }
      }
    }

    .mobileFooter {
      margin-top: 3rem;
      padding: 0;
      align-content: flex-end;
      #arpal {
        padding: 0 2rem 1rem;
      }
      .contatti {
        padding: 0 1rem;
      }
      .ant-menu {
        margin-bottom: 1rem;
        .ant-menu-item {
          height: 1.5rem;
          line-height: 1.5rem;
        }
      }
    }
  }
}

.desktopHeader {
  .logo {
    background-color: $primario;
    line-height: initial;
    padding: 0 3rem;
    a:hover img,
    a:focus img,
    a:active img {
      border-color: $primario;
      border-right-width: 1px !important;
      outline: 0;
      box-shadow: 0 0 0 2px rgba(0, 102, 101, 0.5) !important;
      text-decoration: underline;
    }
    #logo {
      img {
        width: 21rem;
      }
    }
    .headerTitle {
      span {
        font-size: 2rem;
        font-weight: 700;
      }
    }
  }
  .navbar {
    text-align: center;
    line-height: initial;
    margin: 0;
    padding: 0.5rem 0;
    background-color: $bianco;
    .ant-col {
      display: flex;
      justify-content: center;
      .ant-btn {
        color: $primario;
        display: flex;
        align-items: center;
        background-color: $bianco;
        margin-bottom: 0;
      }
    }
  }
}
