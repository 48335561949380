.appFooter {
  padding: 1em 0 0 0;
  background-color: $primario;
  .info {
    background-color: $bianco;
    text-align: center;
    padding: 1rem 0;
    .ant-col {
      .contatti {
        display: grid;
        text-align: right;
        justify-content: center;
      }
    }
  }
  .footerButton {
    margin: 0 3em;
    .ant-col {
      margin-right: 2rem;
      .ant-btn {
        margin: auto 0;
        color: $bianco;
      }
    }
  }
}
