.mobileTable {
  margin-top: 0.51rem;
  padding: 0;
  justify-content: center;
}
.desktopTable {
  padding: 1rem;
  justify-content: center;
  &.inviaLista {
    justify-content: flex-start;
    display: flex;
  }
}
.inviaLista {
  button {
    align-items: center;
    display: flex;
  }
}
.ant-table {
  .ant-table-thead {
    height: auto;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    tr > th {
      background-color: $primario;
      padding: 0;
      color: $bianco;
      &.eliminaSegnalazione{
        font-size: 0;
      }
      &:hover {
        background-color: $primario;
        text-decoration: underline;
      }
    }
    .ant-table-cell {
      color: $bianco;
      .ant-table-filter-column {
        .ant-table-filter-column-title {
          width: 100%;
        }
      }
      .ant-table-column-sorters {
        padding: 0 0 0 0.2rem;
        font-size: 0.9rem;
        font-weight: normal;
        display: block;
      }
      .ant-table-column-sorter-inner {
        vertical-align: top;
      }
      .ant-table-filter-trigger-container {
        display: flex;
        position: relative;
        right: 1.2rem;
        .ant-table-filter-trigger {
          font-size: 0.9rem;
          .anticon-cerca {
            width: 1em;
            height: 1em;
          }
          &:hover {
            color: $bianco;
          }
        }
      }
      .ant-table-column-sorters .ant-table-column-sorter {
        right: 0;
        .ant-table-column-sorter-up,
        .ant-table-column-sorter-down {
          font-size: 0.9rem;
        }
      }
      .ant-empty {
        color: $primario;
        .ant-empty-description {
          color: $primario;
        }
      }
    }
    .ant-table-column-sorter-up.active,
    .ant-table-column-sorter-down.active {
      color: $bianco;
    }
  }
  .ant-table-tbody .ant-table-cell {
    white-space: break-spaces;
    padding: 0.2rem;
    font-size: 0.8rem;
    &.eliminaSegnalazione {
      padding: 0;
      margin: 0;
      text-align: center;
      .ant-btn {
        background-color: $bianco;
        padding: 0;
        margin: 0;
        color: $primario;
        width: auto;
      }
    }
    &.desktopTable {
      font-size: 1rem;
    }
  }
}
.ant-btn.inviamiLista {
  display: flex;
  align-items: center;
}
.ant-table-filter-dropdown {
  .ant-btn {
    background-color: transparent;
  }
}

.fieldLabel {
  font-weight: 700;
  margin-right: 5px;
}

.ant-row.segnalazione {
  margin: 1rem 0;
  .ant-col {
    display: grid;
    a {
      text-align: center;
      margin-top: 1rem;
    }
  }
}
