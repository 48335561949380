#frameMappa {
  width: 100%;
  height: calc(100vh - 112px);
  &.desktopMap {
    height: calc(100vh - 128px);
  }
}

.noClickButton {
  color: $primario;
  cursor: auto;
  &:hover,
  &:focus {
    color: $primario;
  }
}

.ant-drawer-right .ant-drawer-content-wrapper {
  width: auto;
  max-width: 100%;
  .ant-list-lg .ant-list-item {
    padding: 0;
    margin-bottom: 1rem;
    border-bottom: none;
    padding-top: 0.3rem;
    justify-items: end;
    display: grid;
    .ant-btn {
      width: 100%;
      background-color: $bianco;
      color: $primario;
      img {
        margin-right: 1rem;
        max-width: 3rem;
      }
    }
    .ant-list-vertical .ant-list-item-action {
      margin-left: auto;
      padding-top: 1rem;
      padding-bottom: 0.5rem;
    }
  }
}

.mobileDrawer .ant-drawer-content-wrapper {
  width: 100% !important;
}
