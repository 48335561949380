.loginSpidDisabled {
  opacity: 0.3;
  cursor: not-allowed;
}
.row-button {
  margin: 0;
}
.row-button.action {
  margin: 0 1.5rem 1.5rem;
  justify-content: center;
}
.row-button.spid {
  padding-top: 1.5rem;
  justify-content: center;
}
.row-button.immagini {
  margin: 0;
  padding: 0;
  .ant-form-item {
    margin: 0;
    padding: 0;
    .ant-upload.ant-upload-select-picture-card {
      width: auto;
      margin: 0;
      .ant-btn {
        margin-right: 0;
      }
    }
    .ant-upload-list-picture-card .ant-upload-list-item {
      padding: 0;
    }
    .ant-upload-list {
      display: flex;
      align-items: center;
      flex-flow: row wrap;
      .ant-upload-list-picture-card-container {
        flex: auto;
        max-width: max-content;
        margin: 0;
        padding: 0.2rem;
        width: 20%;
        height: auto;
        .ant-btn {
          background-color: transparent;
        }
      }
    }
  }
}

.modalPosition {
  .ant-modal {
    width: calc(50vw) !important;
    height: calc(50vh);
    iframe {
      width: calc(50vw);
      height: calc(50vh);
    }
  }
  &.mobile {
    .ant-modal {
      width: calc(90vw) !important;
      iframe {
        width: calc(90vw);
      }
    }
  }
}

.mobileForm {
  .row-button.immagini .ant-form-item {
    .ant-upload-list-picture-card-container {
      width: 33.33%;
      height: auto;
    }
    .ant-upload.ant-upload-select-picture-card {
      width: 100%;
      .ant-btn {
        margin-right: 0.5rem;
      }
    }
  }
}
.ant-result-success .ant-result-icon > .anticon {
  color: $primario;
}
.ant-result-title {
  color: $primario;
  font-size: 1.5rem;
}
