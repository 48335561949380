$primario: #006665;
$primarioVariante: #97ccbc;
$secondario: #dae8dd;
$bluspid: #06c;
$bianco: white;
$nero: black;
$rosa: #e7747e;
$grigioScuro: #5a5a5a;
$grigioChiaro: #999999;
$rossoScuro: #9d0129;
